<template>
  <div>
    <iframe width="100%" height="1000px" :src="user.opportunity_map_data_studio_link"
            frameborder="0" style="border:0" allowfullscreen></iframe>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import * as accountTypes from '@/modules/account/store/types'

export default
{
  computed:{
    ...mapGetters({
      user: accountTypes.USER
    }),
  }
}
</script>
